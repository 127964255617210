import React, { useContext } from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import icon_phone from '../../../assets/img/Footer/icon_phone.svg';
import icon_mail from '../../../assets/img/Footer/icon_mail.svg';
import icon_location from '../../../assets/img/Footer/icon_location.svg';
import arrow from '../../../assets/img/Footer/btn_arrow.svg';
import { PopupContext } from "../../../App";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  const { setActive } = useContext(PopupContext);
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.content}>
          <h2 className={`${classes.title} font-64`}>
            {t('footer_title')}
          </h2>
          <h3 className={`${classes.subtitle} font-48`}>
            {t('footer_subtitle')}
          </h3>
          <div className={classes.bottom}>
            <a href="tel:+442081571381" className={classes.link}>
              <img className={classes.icon} src={icon_phone} alt=''/>
              +442081571381
            </a>
            <a href={`mailto:support@${hostname}`} className={classes.link}>
              <img className={classes.icon} src={icon_mail} alt=''/>
              support@{hostname}
            </a>
            <div className={classes.link}>
              <img className={classes.icon} src={icon_location} alt=''/>
              40 Cağaloğlu, Eminönü, Istanbul, Turkey
            </div>
          </div>
        </div>
        <div className={classes.buttonWrap}>
          <div
            className={classes.button}
            onClick={() => setActive(true)}
          >
            <img className={classes.arrow} src={arrow} alt=''/>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
