import React from "react";
import classes from './OurValues.module.scss';
import icon_01 from '../../../assets/img/HomePage/OurValues/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/OurValues/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/OurValues/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/OurValues/icon_04.svg';
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'home_our_values_li_01',
    },
    {
        icon: icon_02,
        text: 'home_our_values_li_02',
    },
    {
        icon: icon_03,
        text: 'home_our_values_li_03',
    },
    {
        icon: icon_04,
        text: 'home_our_values_li_04',
    },
];

const OurValues = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.ourValues}>
                <h2 className={`${classes.title} font-48`}>
                    {t('home_our_values_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <p className={`${classes.cardText} font-16`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurValues;
