import logo from '../assets/img/logo.png';
import img1 from '../assets/img/HomePage/Banner/bg.png';
import img2 from '../assets/img/HomePage/Banner/bg_mob.png';
import img3 from '../assets/img/HomePage/Banner/man.png';
import img4 from '../assets/img/VacanciesPage/Banner/bg.png';
import img5 from '../assets/img/VacanciesPage/Banner/woman.png';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
];
