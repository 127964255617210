import React, { useContext } from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";
import { PopupContext } from "../../../App";

const Button = ({ children, to }) => {
  const { lang } = useParams();
  const { setActive } = useContext(PopupContext);
  if (to) return (
    <Link 
      to={`/${lang}/${to}`}
      className={`${classes.button}`}  
    >
      {children}
    </Link>
  );
  return (
    <div onClick={() => setActive(true)} className={`${classes.button}`}>
      {children}
    </div>
  );
};

export default Button;
