import React from "react";
import classes from './Banner.module.scss';
import woman from '../../../assets/img/VacanciesPage/Banner/woman.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={`${classes.title} font-64`}>
                        {t('vacancies_banner_title')}
                    </h1>
                    <div className={`${classes.text} font-16`}>
                        <p>
                            {t('vacancies_banner_text_01')}
                        </p>
                        <p>
                            {t('vacancies_banner_text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_apply_now')}
                        </Button>
                    </div>
                </div>
                <div className={classes.bg}></div>
                <img className={classes.woman} src={woman} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
