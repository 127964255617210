import React from "react";
import classes from './WhyChoose.module.scss';
import image from '../../../assets/img/HomePage/why_choose.png';
import { useTranslation } from "react-i18next";

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whyChoose}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('home_why_choose_title')}
                    </h2>
                    <div className={`${classes.text} font-16 txt-block`}>
                        <p>
                            {t('home_why_choose_text_01')}
                        </p>
                        <p>
                            {t('home_why_choose_text_02')}
                        </p>
                    </div>
                    <ul className={`${classes.list} font-16`}>
                        <li>
                            {t('home_why_choose_li_01')}
                        </li>
                        <li>
                            {t('home_why_choose_li_02')}
                        </li>
                        <li>
                            {t('home_why_choose_li_03')}
                        </li>
                        <li>
                            {t('home_why_choose_li_04')}
                        </li>
                    </ul>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default WhyChoose;
