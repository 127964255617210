import React from "react";
import classes from './Explore.module.scss';
import image from '../../../assets/img/HomePage/explore.png';
import { useTranslation } from "react-i18next";

const Explore = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.explore}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('home_explore_title')}
                    </h2>
                    <div className={`${classes.text} font-16 txt-block`}>
                        <p>
                            {t('home_explore_text_01')}
                        </p>
                        <p>
                            {t('home_explore_text_02')}
                        </p>
                        <p>
                            {t('home_explore_text_03')}
                        </p>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Explore;
