import React from 'react';
import classes from "./Structure.module.scss";
import icon1 from "../../../assets/img/HomePage/structure_icon.svg";
import {useTranslation} from "react-i18next";

const cards = [
    {
        title: 'home_structure_card_01_title',
        text: 'home_structure_card_01_text',
    },
    {
        title: 'home_structure_card_02_title',
        text: 'home_structure_card_02_text',
    },
    {
        title: 'home_structure_card_03_title',
        text: 'home_structure_card_03_text',
    },
    {
        title: 'home_structure_card_04_title',
        text: 'home_structure_card_04_text',
    },
    {
        title: 'home_structure_card_05_title',
        text: 'home_structure_card_05_text',
    },
    {
        title: 'home_structure_card_06_title',
        text: 'home_structure_card_06_text',
    },
];

const Structure = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.features} mt`}>
            <div className="container">
                <h2 className={`${classes.title} font-48`}>
                    {t('home_structure_title')}
                </h2>
                <div className={`${classes.features__header}`}>
                    <img className={`${classes.features__headerImg}`} src={ icon1 } alt=""/>
                    <h3 className={`${classes.features__headerTitle} font-24`}>
                        {t('home_structure_subtitle')}
                    </h3>
                    <p className={`${classes.features__headerDescr} font-16`}>
                        {t('home_structure_text')}
                    </p>
                </div>
                <div className={`custom-scrollbar ${classes.features__listWrap}`}>
                    <div className={`${classes.features__list}`}>
                        {cards.map((card, index) =>
                            <section key={index} className={`${classes.features__item}`}>
                                <div className={`${classes.features__itemDecoDot}`}></div>
                                <h3 className={`${classes.features__itemTitle} font-20`}>
                                    {t(card.title)}
                                </h3>
                                <p className={`${classes.features__itemDescr} font-16`}>
                                    {t(card.text)}    
                                </p>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Structure;
