import _ from 'lodash';
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import classes from './Form.module.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from "react-i18next";
import successGif from '../../../assets/img/success.gif';
import axios from "axios";

const Form = ({ active, setActive }) => {
    const { t } = useTranslation();
    
    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ termsAgree, setTermsAgree ] = useState(false);

    const [ nameValid, setNameValid ] = useState(true);
    const [ phoneValid, setPhoneValid ] = useState(true);
    const [ emailValid, setEmailValid ] = useState(true);
    const [ termsAgreeValid, setTermsAgreeValid ] = useState(true);

    const [ success, setSuccess ] = useState(false);

    const clearInputs = () => {
        setName('');
        setPhone('');
        setEmail('');
        setTermsAgree(false);
        setNameValid(true);
        setEmailValid(true);
        setSuccess(false);
        setTermsAgreeValid(true);
    }

    const checkName = () => {
        if (name.length > 0) {
            setNameValid(true)
            return true;
        }
        setNameValid(false);
        return false;
    }
    const checkPhone = () => {
        if(phone.length >= 10) {
            setPhoneValid(true);
            return true;
        }
        setPhoneValid(false);
        return false;
    }
    const checkEmail = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (expression.test(String(email.toLocaleLowerCase()))) {
            setEmailValid(true)
            return true;
        }
        setEmailValid(false);
        return false;
    }
    const checkAgree = () => {
        if (termsAgree) {
            setTermsAgreeValid(true)
            return true;
        }
        setTermsAgreeValid(false);
        return false;
    }

    
    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(checkName());
        validArr.push(checkPhone());
        validArr.push(checkEmail());
        validArr.push(checkAgree());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };

    const sendTgMsg = async () => {
        const params = {
            name,
            phone,
            email,
        };
        clearInputs();
        try {
            const result = await axios.post('https://talento.ltd/send.php', params);
            if (result.status === 200) {
                setSuccess(true);
            } else {
                alert('Something went wrong. Try again');
            }
        } catch (error) {
            alert(error.message);
        }
    }

    const submit = () => validateInputData() && sendTgMsg();

    useEffect(() => {
        clearInputs();
        const b = $('body'), ovf = "ovf-hidden";
        active ? b.addClass(ovf) : b.removeClass(ovf);
    }, [active]);

    useEffect(() => {
        !nameValid && checkName();
        // eslint-disable-next-line
    }, [ name ]);
    useEffect(() => {
        !phoneValid && checkPhone();
        // eslint-disable-next-line
    }, [ phone ]);
    useEffect(() => {
        !emailValid && checkEmail();
        // eslint-disable-next-line
    }, [ email ]);
    useEffect(() => {
        !termsAgreeValid && checkAgree();
        // eslint-disable-next-line
    }, [ termsAgree ]);

    return (
        <div className={`${classes.form} ${active && classes.formActive}`}>
            <div onClick={() => setActive(false)} className={classes.closeZone}></div>
            <div className={classes.formContent}>
                <div className={`${classes.success} ${success && classes.successActive}`}>
                    <img src={successGif} alt=''/>
                </div>
                <div onClick={() => setActive(false)} className={classes.closeBtn}></div>
                <h4 className={[classes.title, 'font-20'].join(' ')}>
                    {t('form_title_01')} 
                    <br/>{t('form_title_02')}
                </h4>
                <p className={[classes.subtitle, 'font-16'].join(' ')}>
                    {t('form_subtitle')}
                </p>
                <div className={classes.inputs}>
                    <input 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder={t('form_name')}
                        className={`${classes.input} ${!nameValid && classes.incorrect}`}
                    />
                    <PhoneInput
                        value={phone}
                        onChange={phone => setPhone(phone)}
                        country={'tr'}
                        preferredCountries={['tr']}
                        inputProps={{placeholder: t('form_phone')}}
                        containerClass={`${!phoneValid && classes.phoneIntContClass}`}
                    />
                    <input 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder={t('form_email')}
                        className={`${classes.input} ${!emailValid && classes.incorrect}`}
                    />
                    <div className={`${classes.check} ${!termsAgreeValid && classes.incorrect}`}>
                        <div 
                            className={`${classes.checkBox} ${termsAgree && classes.checkBoxActive}`}
                            onClick={() => setTermsAgree(value => !value)}
                        >
                        </div>
                        <p className={classes.checkText}>
                            I agree with <a href="/docs/TERMS_AND_CONDITIONS.pdf" target="_blank">terms and conditions</a> of this website
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <span 
                            className={classes.submit}
                            onClick={submit}
                        >
                            {t('form_submit')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;
