import React from "react";
import { vacancies } from "./vacancies"; 
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/VacanciesPage/Banner/Banner";
import Vacancy from "../../components/VacanciesPage/Vacancy/Vacancy";

const VacanciesPage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
            <Vacancy data={vacancies.sales} />
            <Vacancy data={vacancies.retention} />
            <Footer />
        </div>
    );
};

export default VacanciesPage;
