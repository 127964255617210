import React, { useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        title: 'home_faq_01_title',
        text: 'home_faq_01_text',
    },
    {
        title: 'home_faq_02_title',
        text: 'home_faq_02_text',
    },
    {
        title: 'home_faq_03_title',
        text: 'home_faq_03_text',
    },
    {
        title: 'home_faq_04_title',
        text: 'home_faq_04_text',
    },
    {
        title: 'home_faq_05_title',
        text: 'home_faq_05_text',
    },
    {
        title: 'home_faq_06_title',
        text: 'home_faq_06_text',
    },
    {
        title: 'home_faq_07_title',
        text: 'home_faq_07_text',
    },
    {
        title: 'home_faq_08_title',
        text: 'home_faq_08_text',
    },
];

const Faq = () => {
    const { t } = useTranslation();
    const [ selectedTab, setSelectedTab ] = useState(0);
    const setTab = (value) => selectedTab === value 
        ? setSelectedTab(-1) 
        : setSelectedTab(value);
    return (
        <section className="mt container">
            <div className={classes.faqsWrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('home_faq_title')}
                    </h2>
                    <p className={`${classes.text} font-16`}>
                        {t('home_faq_text')}
                    </p>
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index}
                            onClick={() => setTab(index)}
                            className={`
                                ${classes.tab} 
                                ${selectedTab === index && classes.active} 
                                font-16 no-select
                            `}
                        >
                            <div className={classes.tabHeader}>
                                <div className={classes.tabTitle}>
                                    {t(tab.title)}
                                </div>
                                <div className={classes.tabStatus}></div>
                            </div>
                            <p className={classes.tabText}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
