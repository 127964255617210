import image_01 from '../../assets/img/VacanciesPage/vacancy_01.png';
import image_02 from '../../assets/img/VacanciesPage/vacancy_02.png';

export const vacancies = {
    sales: {
        banner: {
            title: 'vacancies_sales_banner_title',
            text: 'vacancies_sales_banner_text',
            image: image_01,
        },
        reqs: {
            title: 'vacancies_sales_reqs_title',
            list: [
                'vacancies_sales_reqs_li_01',
                'vacancies_sales_reqs_li_02',
                'vacancies_sales_reqs_li_03',
                'vacancies_sales_reqs_li_04',
                'vacancies_sales_reqs_li_05',
                'vacancies_sales_reqs_li_06',
            ],
            text: 'vacancies_sales_reqs_text',
        },
        resp: {
            title: 'vacancies_sales_resp_title',
            list: [
                'vacancies_sales_resp_li_01',
                'vacancies_sales_resp_li_02',
                'vacancies_sales_resp_li_03',
                'vacancies_sales_resp_li_04',
                'vacancies_sales_resp_li_05',
                'vacancies_sales_resp_li_06',
            ],
        },
        overview: {
            title: 'vacancies_sales_overview_title',
            text: [
                'vacancies_sales_overview_text_01',
                'vacancies_sales_overview_text_02',
            ],
        },
        apply: {
            title: 'vacancies_sales_apply_title',
            text: 'vacancies_sales_apply_text',
        },
        compensation: {
            title: 'vacancies_sales_compensation_title',
            list: [
                'vacancies_sales_compensation_li_01',
                'vacancies_sales_compensation_li_02',
            ],
        },
    },
    retention: {
        banner: {
            title: 'vacancies_reten_banner_title',
            text: 'vacancies_reten_banner_text',
            image: image_02,
        },
        reqs: {
            title: 'vacancies_reten_reqs_title',
            list: [
                'vacancies_reten_reqs_li_01',
                'vacancies_reten_reqs_li_02',
                'vacancies_reten_reqs_li_03',
                'vacancies_reten_reqs_li_04',
                'vacancies_reten_reqs_li_05',
                'vacancies_reten_reqs_li_06',
            ],
        },
        resp: {
            title: 'vacancies_reten_resp_title',
            list: [
                'vacancies_reten_resp_li_01',
                'vacancies_reten_resp_li_02',
                'vacancies_reten_resp_li_03',
                'vacancies_reten_resp_li_04',
                'vacancies_reten_resp_li_05',
                'vacancies_reten_resp_li_06',
            ],
        },
        overview: {
            title: 'vacancies_reten_overview_title',
            text: [
                'vacancies_reten_overview_text_01',
            ],
        },
        apply: {
            title: 'vacancies_reten_apply_title',
            text: 'vacancies_reten_apply_text',
        },
        compensation: {
            title: 'vacancies_reten_compensation_title',
            list: [
                'vacancies_reten_compensation_li_01',
                'vacancies_reten_compensation_li_02',
            ],
        },
    },
};