import HomePage from '../pages/HomePage/HomePage';
import VacanciesPage from '../pages/VacanciesPage/VacanciesPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/vacancies', element: VacanciesPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/vacancies', element: VacanciesPage },
];

export const headerRoutes = [
    { id: 0, path: 'vacancies', text: 'header_vacancies' },
];
