import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import OurValues from "../../components/HomePage/OurValues/OurValues";
import WhyChoose from "../../components/HomePage/WhyChoose/WhyChoose";
import Explore from "../../components/HomePage/Explore/Explore";
import Faq from "../../components/HomePage/Faq/Faq";
import Structure from "../../components/HomePage/Structure/Structure";
import Banner from "../../components/HomePage/Banner/Banner";

const HomePage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
            <OurValues />
            <Structure />
            <WhyChoose />
            <Explore />
            <Faq />
            <Footer />
        </div>
    );
};

export default HomePage;
